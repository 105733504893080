var _ = require('underscore');

var ERRORS = {
  generic: {
    level: 'error',
    refresh: true
  },
  limit: {
    level: 'error',
    error: '超时',
    message: '服务器响应时间过长。 请稍后重试.',
    refresh: true
  },
  no_data_available: {
    level: 'alert',
    error: '无可用数据',
    message: '数据的过滤器组合没有结果。尝试调整您的过滤器，或缩放和平移地图以调整地图视图.',
    refresh: false
  },
  too_many_bins: {
    level: 'error',
    error: '返回的容器过多',
    message: '尝试选择粒度更小的聚合或过滤数据源.',
    refresh: false
  },
  dataview: {
    level: 'error',
    refresh: false
  }
};

module.exports = function (error) {
  var type = error && error.type && _.has(ERRORS, error.type)
    ? error.type
    : 'generic';
  return _.extend({}, error, ERRORS[type]);
};
