import Vue from 'vue';
import VueI18n from 'vue-i18n';
import './backbone-i18n';

// Locales
import en from 'new-dashboard/i18n/locales/en';
import cn from 'new-dashboard/i18n/locales/zh-cn';

Vue.use(VueI18n);

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: 'zh-cn',
  messages: {
    'zh-cn': cn,
    'en-US': en
  }
});

export default i18n;
